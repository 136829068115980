@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importing the local font */
@font-face {
  font-family: "CustomFont"; /* Choose a name for your font */
  src: url("./assets/font.ttf") format("truetype"); /* Ensure the path is correct */
  font-weight: normal;
  font-style: normal;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "CustomFont", sans-serif;
  color: rgb(0, 0, 0);
}

/* Gradient background for all pages except Main */
.gradient-background {
  background: linear-gradient(to bottom right, #30f90f, #f6d416, #f84cd6);
  min-height: 100vh;
  overflow: hidden;
}

/* Background image for Main page */
.image-background {
  background-image: url("./assets/background.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Remove Increase/Decrease Buttons on Inputs */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove number input arrows */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
  border-radius: 1px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}

/* Text selection styling */
::-moz-selection {
  color: rgb(202, 36, 217);
  background: rgb(0, 0, 0);
}
::selection {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}

.custom-blur {
  background-color: rgba(196, 210, 169, 0.6); /* Your color with opacity */
  backdrop-filter: blur(10px); /* Adjust blur as needed */
}
